<script setup lang="ts">
withDefaults(
  defineProps<{
    variant?: string;
  }>(),
  {
    variant: "primary",
  },
);
</script>

<template>
  <span class="badge">
    <slot />
  </span>
</template>

<style scoped lang="postcss">
.badge {
  @apply inline rounded-full  bg-ccli-light-blue pl-2 pr-3  align-middle font-display text-sm font-bold uppercase italic text-primary;
}
</style>
