<script setup lang="ts">
import img from "@/assets/ccli-mark.svg";
</script>

<template>
  <router-link
    to="/"
    class="site-logo-link-mark"
  >
    <img
      :src="img"
      :alt="$t('SV.LIT_SongSelectLogo')"
      width="39"
      height="39"
    />
  </router-link>
</template>

<style scoped lang="postcss">
.site-logo-link-mark {
  @apply relative flex h-full items-center justify-center;
  width: 39px;
}

.dark .site-logo-link-mark:deep() {
  img {
    filter: brightness(0) invert(100);
  }
}
</style>
