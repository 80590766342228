<script setup lang="ts">
withDefaults(
  defineProps<{
    iconEnd?: string;
    iconStart?: string;
    icon?: string;
  }>(),
  {
    iconEnd: "",
    iconStart: "",
    icon: "",
  },
);
</script>

<template>
  <FAIcon
    v-if="iconStart"
    :class="iconStart"
    class="icon-start"
  />
  <slot>
    <FAIcon :class="icon" />
  </slot>
  <FAIcon
    v-if="iconEnd"
    :class="iconEnd"
    class="icon-end"
  />
</template>

<style scoped lang="postcss">
.icon-start {
  @apply mr-1;
}
.icon-end {
  @apply ml-1;
}
</style>
