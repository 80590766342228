<script setup lang="ts">
defineProps<{
  title: string;
  icon: string;
}>();
const emit = defineEmits<{
  (e: "click", evt: Event): void;
}>();
</script>

<template>
  <button
    class="round-icon-button"
    type="button"
    :title="title"
    @click="(e) => $emit('click', e)"
  >
    <FAIcon
      class="fa-fw"
      :class="icon"
    />
  </button>
</template>

<style scoped lang="postcss">
.round-icon-button {
  @apply btn btn-circle bg-light-700  hover:bg-gray-100;
}
</style>
