<script setup lang="ts">
import SSLogo from "@/assets/sslogo.svg";
</script>

<template>
  <router-link
    to="/"
    class="site-logo-link"
  >
    <img
      :src="SSLogo"
      :alt="$t('SV.LIT_SongSelectLogo')"
      width="200"
      height="39"
    />
  </router-link>
</template>

<style scoped lang="postcss">
.site-logo-link {
  @apply relative flex h-full items-center justify-center;
  width: 175px;
}

.dark .site-logo-link:deep() {
  img {
    filter: brightness(0) invert(100);
  }
}
</style>
