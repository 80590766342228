<script setup lang="ts">
withDefaults(
  defineProps<{
    to?: string;
    href?: string;
    newBadge?: boolean;
    exact?: boolean;
    placeholder?: boolean;
    queryList?: string;
    gaLabel?: string;
    noLink?: boolean;
    subitem?: boolean;
  }>(),
  {
    to: "",
    href: "",
    newBadge: false,
    factive: false,
    placeholder: false,
    queryList: "",
  },
);
</script>

<template>
  <li>
    <span
      v-if="placeholder"
      class="site-nav-link"
    >
      <span
        class="placeholder"
        style="width: 200px"
      />
    </span>
    <span
      v-else-if="noLink"
      class="site-nav-link"
      :class="{ subitem: subitem }"
    >
      <slot />
      <Badge
        v-if="newBadge"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </span>
    <router-link
      v-else-if="to"
      :to="to"
      :active-class="exact ? undefined : 'active'"
      :class="{
        active: $route.fullPath === to || $route.query.list === queryList,
        subitem: subitem,
      }"
      class="site-nav-link"
      :data-menu-item="gaLabel"
    >
      <div>
        <slot />
      </div>
      <Badge
        v-if="newBadge"
        class="ms-2"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </router-link>

    <a
      v-else
      :href="href ?? '#'"
      target="_blank"
      class="site-nav-link"
      :class="{ subitem: subitem }"
      :data-menu-item="gaLabel"
    >
      <slot />
      <Badge
        v-if="newBadge"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </a>
  </li>
</template>

<style scoped lang="postcss">
.site-nav-link {
  @apply flex items-center gap-2 border-l-2 py-2 pl-3 leading-4 text-gray-600 transition-all;
}
.site-nav-link.subitem {
  @apply pl-5;
}
a.site-nav-link {
  &:hover,
  &:focus {
    @apply text-gray-975;
  }

  &.active {
    @apply border-l-2 border-l-primary text-gray-900;
  }
}
:deep() {
  .badge {
    @apply badge-sm;
  }
}

@media screen {
  html.dark {
    a.site-nav-link {
      @apply text-gray-300;
      &:hover,
      &:focus {
        @apply text-light-100;
      }

      &.active {
        @apply border-l-2 border-l-primary text-light-100;
      }
    }
  }
}
</style>
